<template>
  <v-dialog v-model="smsDialog" max-width="600px" @input="closeDialog">
    <v-card>
      <v-card-title> Phone Verification </v-card-title>
      <v-card-text>
        <!-- <country-code :phone="phone" @updatePhone="updatePhone" /> -->
        <v-layout row wrap>
          <v-flex xs12>
            <v-checkbox
              label="Agree"
              v-model="consentFlag"
              value="true"
              color="primary"
            ></v-checkbox>
          </v-flex>

          <v-flex xs12> {{ consentMessage }} </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          :disabled="!consentFlag"
          @click="
            smsDialog = false
            save()"
          >Save</v-btn
        >
        <v-btn text @click="closeDialog">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CountryCode from './CountryCode'

export default {
  components: {
    CountryCode
  },
  props: {
    // phone: {
    //   type: String
    // },
    consentMessage: {
      type: String
    },
    openDialog: {
      type: Boolean
    },
    smsConsent: {
      type: Boolean
    }
  },
  data() {
    return {
      consentFlag: false,
      phoneNumber: null,
      smsDialog: false
    }
  },
  watch: {
    openDialog: function() {
      this.smsDialog = this.openDialog
      // this.phoneNumber = this.phone
      this.consentFlag = this.smsConsent
    }
  },
  methods: {
    save() {
      this.$emit('savePhone', {
        // phoneNumber: this.phoneNumber,
        smsConsent: this.consentFlag
      })
      this.$emit('closeDialog')
    },
    // updatePhone(phoneNumber) {
    //   this.phoneNumber = phoneNumber
    // },
    closeDialog() {
      this.smsDialog = false
      this.$emit('closeDialog')
    }
  }
}
</script>

<style></style>
