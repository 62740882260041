<template>
  <v-container grid-list- fluid v-if="!loading && profile">
    <v-layout align-center justify-center row fill-height class="mb-6">
      <v-flex xs12 sm9>
        <v-card tile>
          <v-app-bar flat>
            <v-toolbar-title>Notification Settings</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items> </v-toolbar-items>
            <v-menu bottom right offset-y>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon> <v-icon>more_vert</v-icon> </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, i) in items" :key="i">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-app-bar>
          <v-card-text>
            <v-list three-line subheader>
              <!-- <v-subheader>Policy</v-subheader> -->
              <v-list-item>
                <v-list-item-content class="note_lx">
                  <v-list-item-title class="pb-3"
                    >Policy Acknowledgements</v-list-item-title
                  >
                  <v-list-item-subtitle
                    v-if="!isChef"
                    class="note"
                    v-html="$t('Common.notification_title_1')"
                  >
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="isChef">
                    <p>
                      It is important that TakeIn can notify you of important
                      events.
                    </p>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="mt-0 pt-0">
                <v-list-item-content>
                  <v-list-item-title>Notifications</v-list-item-title>
                  <v-list-item-subtitle
                    v-html="$t('Common.notification_title_2')"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-subheader>Notification Options</v-subheader>
            <v-layout row wrap class="ma-3">
              <v-flex xs12 sm6>
                <v-switch
                  v-model="allowSms"
                  @change="checkIfConsentNeeded"
                  label="SMS"
                  color="primary"
                ></v-switch>
                Send notifications through SMS text message
              </v-flex>
              <v-flex xs12 sm4 v-if="allowSms">
                <!-- <v-text-field
                  @click="changeItem"
                  solo
                  label="Mobile Phone"
                  v-model="phone"
                ></v-text-field> -->
                <country-code :phone="phone" @updatePhone="updatePhone" />
                <!-- <v-btn @click="smsDialog = true">
                  Edit Phone Number
                  <v-icon>
                    edit
                  </v-icon>
                </v-btn> -->
              </v-flex>
            </v-layout>

            <v-divider />

            <v-layout row wrap class="ma-3">
              <v-flex xs12 sm6>
                <v-switch
                  v-model="allowEmail"
                  label="Email"
                  color="primary"
                ></v-switch>
                Send notifications through email
              </v-flex>
              <v-flex xs12 sm4>
                <v-text-field
                  label="Email Address"
                  :readonly="true"
                  v-model="email"
                  solo
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-divider />

            <v-layout row wrap class="ma-3">
              <v-flex xs12 sm6>
                <v-switch
                  v-model="allowPush"
                  label="Mobile Notifications"
                  color="primary"
                ></v-switch>
                Send notifications through Mobile App Push notification
              </v-flex>
              <v-flex xs12 sm4> </v-flex>
            </v-layout>

            <v-divider />

            <v-layout row wrap class="ma-3">
              <v-flex xs12>
                <v-btn class="mr-3" color="primary" @click.prevent="save"
                  >Save</v-btn
                >
                <v-btn color="" router :to="'/settings'">Close</v-btn>
              </v-flex>
            </v-layout>

            <v-divider />
          </v-card-text>

          <div style="flex: 1 1 auto;"></div>
        </v-card>
      </v-flex>
    </v-layout>
    <sms-consent-view
      :openDialog="smsDialog"
      :consentMessage="consentMessage"
      :smsConsent="smsConsent"
      @savePhone="savePhone"
      @closeDialog="closeDialog"
    />

    <!--
      <v-dialog v-model="smsDialog"
                max-width="600px">
        <v-card>
          <v-card-title>
            Phone Verification
          </v-card-title>
          <v-card-text>
            <country-code :phone='phone'/>

            <v-layout row
                      wrap>
              <v-flex xs12>
                <v-checkbox label="Agree"
                            v-model="smsConsent"
                            value="true"></v-checkbox>
              </v-flex>

              <v-flex xs12>
                {{consentMessage}}
              </v-flex>
            </v-layout>

          </v-card-text>
          <v-card-actions>
            <v-btn color="primary"
                   :disabled="!smsConsent"
                   @click="smsDialog=false; save()">Save</v-btn>
            <v-btn text
                   @click="smsDialog=false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    -->
  </v-container>
</template>

<script>
  import { mapGetters } from "vuex";
  import SmsConsentView from "./SmsConsent";
  import CountryCode from './CountryCode'
  export default {
    components: {
      SmsConsentView,
      CountryCodeg
    },
    data() {
      return {
        smsConsent: false,
        allowSms: false,
        phone: null,
        smsDialog: false,
        allowEmail: true,
        email: null,
        allowPush: true,
        consentMessage: null,

        items: [
          {
            title: "Undo",
          },
        ],
      };
    },
    computed: {
      ...mapGetters({
        profile: "profile",
        loading: "loading",
        user: "user",
        isChef: "isChef",
        getMessage: "getMessage",
      }),
    },
    watch: {
      profile: function() {
        if (this.userSettings && this.profile.settings) {
          this.allowEmail = this.profile.settings.allowEmail;
          this.allowSms = this.profile.settings.allowSms;
          this.allowPush = this.profile.settings.allowPush;
          this.smsConsent = this.profile.settings.smsConsent || false;
        }
      },
      smsDialog: function() {
        if (!this.smsDialog) {
          this.allowSms = this.smsConsent;
        }
      },
    },

    mounted() {
      this.$store.dispatch("loadMessage", "consents").then(() => {
        this.consentMessage = this.getMessage("consents")
          ? this.getMessage("consents").notification
          : null;
      });
      if (this.profile && this.profile.settings) {
        this.allowEmail = this.profile.settings.allowEmail;
        this.allowSms = this.profile.settings.allowSms;
        this.allowPush = this.profile.settings.allowPush;
        this.smsConsent = this.profile.settings.smsConsent || false;
      }
    },
    methods: {
      closeDialog() {
        this.smsDialog = false;
        //this.allowSms = false;
      },
      changeItem() {
        this.smsDialog = true;
      },
      checkIfConsentNeeded() {
        if (this.allowSms && !this.smsConsent) {
          this.smsDialog = true;
        }
        if (!this.allowSms) {
          this.phone = null;
          this.smsConsent = false;
          this.smsDialog = false;
        }
      },
      savePhone(payload) {
        if (payload) {
          this.smsConsent = payload.smsConsent;
        }
        this.smsDialog = false
        this.save()
      },
      save() {
        this.$store.dispatch("saveSettings", {
          // type: "notification_settings",
          allowSms: this.allowSms,
          allowEmail: this.allowEmail,
          allowPush: this.allowPush,
          mobile: this.phone,
          smsConsent: this.smsConsent || false,
        });
      },
      updatePhone(phoneNumber) {
      this.phone = phoneNumber
    },
    },
  };
</script>
<style scoped>
  @media only screen and (max-width: 750px) {
    .note {
      font-size: 0.7em;
    }

    .note_lx {
      height: 100px;
    }
  }
</style>
